import { firebase } from "./Firebase.js";
import axios from "axios";

const storage = firebase.storage();

const getStorageLogoGC = async xrestaurant => {
  const storageRef = storage.ref();
  var pathReference = storageRef.child(
    "basilmenu/" + xrestaurant + "/" + xrestaurant + "_logo"
  );
  //const pathReference = storage.ref("clients" + slash + "/logo.png");
  var imgurl;
  await pathReference
    .getDownloadURL()
    .then(async function (url) {
      imgurl = url;
    })
    .catch(function (error) {
      // Handle any errors
      console.log(error);
    });
  //console.log(imgurl)
  return imgurl;
};

const validateinputs = async (
  first,
  last,
  email,
  sms,
  address1,
  city,
  zip,
  state,
  visit_size
) => {
  if (
    first === undefined ||
    first === "" ||
    last === undefined ||
    last === "" ||
    address1 === undefined ||
    address1 === "" ||
    city === undefined ||
    city === "" ||
    state === undefined ||
    state === "" ||
    zip === undefined ||
    zip === "" ||
    visit_size === undefined ||
    visit_size === ""
  ) {
    return [9, "All fields are required."];
  } else if (
    (email === undefined && sms === undefined) ||
    (email === "" && sms === "") ||
    (email === undefined && sms === "") ||
    (email === "" && sms === undefined)
  ) {
    return [0, "Please enter an email address or phone number."];
  } else if (
    sms !== "" &&
    sms !== undefined &&
    email !== "" &&
    email !== undefined
  ) {
    if (email.indexOf("@") !== -1) {
      if (email.substring(email.indexOf("@")).indexOf(".") < 2) {
        if (sms.match(/[^0-9]/g) || sms.length !== 10) {
          return [0, "Please enter an email address or phone number."];
        } else {
          return [1, "Please enter a valid email address."];
        }
      } else {
        if (sms.match(/[^0-9]/g) || sms.length !== 10) {
          return [
            2,
            "Please enter a valid phone number without parenthesis or dashes."
          ];
        } else return true;
      }
    } else {
      if (sms.match(/[^0-9]/g) || sms.length !== 10) {
        return [0, "Please enter an email address or phone number."];
      }
      return [1, "Please enter a valid email address."];
    }
  } else if (sms !== "" && sms !== undefined) {
    if (sms.match(/[^0-9]/g) || sms.length !== 10) {
      return [
        2,
        "Please enter a valid phone number without parenthesis or dashes."
      ];
    } else return true;
  } else if (email !== "" && email !== undefined) {
    if (email.indexOf("@") !== -1) {
      if (email.substring(email.indexOf("@")).indexOf(".") < 2) {
        return [1, "Please enter a valid email address."];
      } else return true;
    } else {
      return [1, "Please enter a valid email address."];
    }
  } else return true;
};

const registerguest = async (xrestaurant, user, visit) => {
  try {
    const response = await axios.post(
      //basil call
      "/registerParty",
      { user, xrestaurant, visit },
      { headers: { "Content-Type": "application/json" } }
    );
    console.log(response)
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export { validateinputs, getStorageLogoGC, registerguest };
