//import firebase from "firebase";
import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";
import "firebase/analytics";

/******************************************/
/******************************************/
/****************** DEV *******************/
/******************************************/
/******************************************/
/*
var firebaseConfig = {
    apiKey: "AIzaSyCAwy7vUp0YPwgmlaI_jEsHVkU_ZhH7Z4w",
    authDomain: "devbasilmenu.firebaseapp.com",
    databaseURL: "https://devbasilmenu.firebaseio.com",
    projectId: "devbasilmenu",
    storageBucket: "devbasilmenu.appspot.com",
    messagingSenderId: "489429041839",
    appId: "1:489429041839:web:d4d9abb2f3e596985f4a5a",
    measurementId: "G-2RYRPBSX4F"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

/******************************************/
/******************************************/
/***************** PROD *******************/
/******************************************/
/******************************************/

var firebaseConfig = {
  apiKey: "AIzaSyDBelI3T7gAEsNJJgwWhfqaLsJAUsodjbo",
  authDomain: "prodbasilmenu.firebaseapp.com",
  databaseURL: "https://prodbasilmenu.firebaseio.com",
  projectId: "prodbasilmenu",
  storageBucket: "prodbasilmenu.appspot.com",
  messagingSenderId: "482431064474",
  appId: "1:482431064474:web:6f457cda0e0fafcc80c223",
  measurementId: "G-Q2RP0L8CN3"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

/******************************************/

export { firebase };
