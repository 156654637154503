import React from "react";
//import logo from '../images/basillogo.png';
import {
  Form,
  Image,
  Dimmer,
  Loader,
  Segment,
  Checkbox,
  Divider,
  Button,
  Modal
  //Modal
} from "semantic-ui-react";
import {
  getStorageLogoGC,
  registerguest,
  validateinputs
} from "./RegistrationFunctions";
import basilmenulogo from "./images/Basil_menu_top.png";
import basillogosmall from "./images/Basil_menu_left_small_black.png";
import { css } from "@emotion/core";
import RingLoader from "react-spinners/RingLoader";

const override = css`
  display: inline-block;
  left: 0;
`;

class GuestRegistration extends React.Component {
  constructor(props, context) {
    super();
    this.state = {
      loading: true,
      open: true
      //u: props.match.url,
      //xrestaurant: xrestaurant
    };
  }
  async componentDidMount() {
    //console.log(this.props);
    var logo = await getStorageLogoGC(this.props.match.params.restaurant);
    this.setState({
      logo,
      loading: false,
      submitted: false,
      user: {},
      visit: {}
    });
  }

  loadingPage = async status => {
    if (!status) {
      return null;
    } else {
      return (
        <Dimmer style={{ zIndex: "999999999999" }} active page>
          <Loader content="Registering..." />
        </Dimmer>
      );
    }
  };

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, [name + "err"]: false });

  handleCheckbox = ({ target }) => {
    this.setState({ [target.id]: target.checked });
  };

  validationOpen = async (message, basic, color, cn = "", redirect) => {
    //console.log(basic);
    if (basic === true) {
      var submitmessage = "OK";
    } else {
      submitmessage = "PROCEED TO MENU";
      color = "black";
    }

    //console.log(message);
    if (redirect === true) {
      window.location.href =
        "https://qr.basil.menu/" + this.props.match.params.restaurant;
    }
    if (!message || message.length < 3) {
      this.setState({ validationerr: null });
      return null;
    } else {
      return (
        <Modal
          style={{ zIndex: "999999999999" }}
          basic={basic}
          closeOnDimmerClick
          open={true}
          size="small"
          className={"emailvalidationerror" + cn}
        >
          {/*<Header icon>
                        <Icon name='archive' />
        Archive Old Messages
                    </Header>*/}
          <Modal.Content>
            <div>{message}</div>
          </Modal.Content>
          <Button
            basic={basic}
            className={cn + "button"}
            color={color}
            inverted={basic}
            onClick={() => this.validationOpen(null, null, null, null, !basic)}
          >
            {submitmessage}
          </Button>
        </Modal>
      );
    }
  };

  handleSubmit = async () => {
    const {
      logo,
      first,
      last,
      email,
      sms,
      address1,
      address2,
      city,
      zip,
      state,
      visit_size,
      acknowledge,
      marketing
    } = this.state;

    const valid = await validateinputs(
      first,
      last,
      email,
      sms,
      address1,
      city,
      zip,
      state,
      visit_size
    );
    //console.log(valid);
    if (valid === true && acknowledge === true) {
      var user = {
        first,
        last,
        email,
        address1,
        city,
        state,
        zip,
        marketing
      };
      if (address2 !== undefined && address2 !== "") {
        user.address2 = address2;
        var a2 = address2;
      } else {
        a2 = "";
      }

      if (sms !== undefined && sms !== "") {
        user.sms = "+1" + sms;
      }
      if (user.marketing === undefined) {
        user.marketing = true;
      }

      var visit = {};
      const now = new Date();
      ////////////////////////////////////////////////////////////////////////////////
      function pad(n) { return n < 10 ? "0" + n : n; }
      var fixeddate = pad(now.getMonth() + 1) + "-" + pad(now.getDate()) + "-" + now.getFullYear();
      console.log(fixeddate)
      ////////////////////////////////////////////////////////////////////////////////
      visit.visit_id = Date.now();
      visit.visit_size = visit_size;
      visit.visit_date = now.toLocaleDateString('en-US');
      console.log(now)
      console.log(now.toLocaleDateString('en-US'))
      var hours = now.getHours()
      var minutes = now.getMinutes();
      if (hours < 10) {
        hours = "0" + String(hours)
      }
      if (minutes < 10) {
        minutes = "0" + String(minutes)
      }
      visit.visit_time = hours + ":" + minutes;

      const loader = await this.loadingPage(true);
      this.setState({ loader });
      const send = await registerguest(
        this.props.match.params.restaurant,
        user,
        visit
      ); //{}
      console.log(send)
      //send.data = ""
      if (send.data === "999" || send.data.name === "Error") {
        console.log("err");
        const validationerr = await this.validationOpen(
          "Check-in failed. Please try again and ensure that the phone number you entered has not been checked in under a different email address at this restaurant in the past.",
          true,
          "red"
        );
        this.setState({ validationerr, loader: null });
      } else if (
        send !== null &&
        send.data.id === undefined &&
        send.data !== ""
      ) {
        console.log(send)
        const validationerr = await this.validationOpen(
          "Check-in failed. Please try again and ensure that the phone number you entered has not been checked in under a different email address at this restaurant in the past",
          true,
          "red"
        );
        this.setState({ validationerr, loader: null });
      } else {
        const validationerr = await this.validationOpen(
          <React.Fragment>
            <Segment className="registered">
              <div>
                <RingLoader css={override} size={50} color={"#006F32"} />
                <Image size="small" className="conflogo" src={logo} />
                <Divider />
                <p className="conftext">
                  <span className="conflabel">Name: </span>
                  {first + " " + last}
                </p>
                <p className="conftext">
                  <span className="conflabel">Email: </span>
                  {email}
                </p>
                <p className="conftext">
                  <span className="conflabel">Phone: </span>
                  {sms}
                </p>
                <p className="conftext">
                  <span className="conflabel">Address: </span>
                  {address1 + " " + a2}
                </p>
                <p className="conftext">
                  <span className="conflabel">City: </span>
                  {city}
                </p>
                <p className="conftext">
                  <span className="conflabel">State/Zip: </span>
                  {state + ", " + zip}
                </p>
                <p className="conftext">
                  <span className="conflabel">Checked In At: </span>
                  {now.toDateString() +
                    " " +
                    now.toLocaleTimeString()}
                </p>
                <p className="conftext">
                  <span className="conflabel">Party Size: </span>
                  {visit.visit_size}
                </p>
              </div>
            </Segment>
            <p>
              Thank you for checking in. Make sure to show this confirmation
              screen to the host before closing the page.
            </p>
          </React.Fragment>,
          false,
          "green",
          "conf"
        );
        this.setState({
          validationerr,
          subscribed: true,
          loader: null,
          visit,
          user
        });
      }
    } else if (!acknowledge) {
      const validationerr = await this.validationOpen(
        "Please check the confirmation statement to confirm that all information is complete, accurate, and in accordance with COVID-19 guidelines and re-submit the form. Thank you for your cooperation.",
        true,
        "green"
      );
      //all info entered is complete, accurate, and in accordance with the COVID - 19 guidelines.'

      this.setState({ validationerr });
    } else {
      const validationerr = await this.validationOpen(valid[1], true, "green");

      if (valid[0] === 0) {
        var emailerr = true;
        var smserr = true;
      } else if (valid[0] === 1) {
        emailerr = true;
        smserr = false;
      } else if (valid[0] === 2) {
        emailerr = false;
        smserr = true;
      }
      this.setState({ emailerr, smserr, validationerr });
    }
  };

  render() {
    const {
      loading,
      logo,
      submitted,
      loader,
      validationerr,
      user,
      visit,
      emailerr,
      smserr
    } = this.state;

    if (!loading && !submitted) {
      return (
        <React.Fragment>
          <div className="splashwrap" id="splashscreen">
            <Image
              centered
              size="tiny"
              className="splashlogo"
              src={basilmenulogo}
            />
            <Loader active inline="centered" size="medium" />
          </div>
          <Image
            size="small"
            id="restaurantlogo"
            src={logo} /*onClick={this.handleRestaurantHomeClick.bind(this)}*/
          />

          <Segment basic className="emailsignupsegment">
            {loader}
            {validationerr}
            <p className="newsletternote">
              {
                "Please register your party in accordance with COVID-19 regulations"
              }
            </p>
            <Form className="emailsignupform" onSubmit={this.handleSubmit}>
              <Form.Input
                placeholder="First Name"
                name="first"
                value={user.first}
                className="emailsignupinput"
                onChange={this.handleChange}
              />
              <Form.Input
                placeholder="Last Name"
                name="last"
                value={user.last}
                className="emailsignupinput"
                onChange={this.handleChange}
              />

              <Form.Input
                error={emailerr}
                placeholder="Email"
                name="email"
                value={user.email}
                className="emailsignupinput"
                onChange={this.handleChange}
              />
              {/*<p style={{ textAlign: 'center', margin: '0.5em', fontWeight: '500' }}>AND/OR</p>*/}
              <Form.Input
                error={smserr}
                placeholder="Phone Number"
                name="sms"
                value={user.sms}
                className="emailsignupinput"
                onChange={this.handleChange}
              />
              <Form.Input
                placeholder="Address Line 1"
                name="address1"
                value={user.address1}
                className="emailsignupinput"
                onChange={this.handleChange}
              />
              <Form.Input
                placeholder="Address Line 2"
                name="address2"
                value={user.address2}
                className="emailsignupinput"
                onChange={this.handleChange}
              />
              <Form.Input
                placeholder="City"
                name="city"
                value={user.city}
                className="emailsignupinput"
                onChange={this.handleChange}
              />
              <Form.Input
                placeholder="State"
                name="state"
                value={user.state}
                className="emailsignupinput"
                onChange={this.handleChange}
              />
              <Form.Input
                placeholder="Zip Code"
                name="zip"
                value={user.zip}
                className="emailsignupinput"
                onChange={this.handleChange}
              />
              <Form.Input
                placeholder="Party Size"
                name="visit_size"
                value={visit.visit_size}
                className="emailsignupinput"
                onChange={this.handleChange}
              />

              <Checkbox
                id="acknowledge"
                onChange={this.handleCheckbox}
                label="I hereby confirm that all info entered is complete, accurate, and in accordance with the COVID-19 guidelines."
              />
              <Checkbox
                id="marketing"
                onChange={this.handleCheckbox}
                defaultChecked
                label="I would like to receive marketing materials, upcoming event info, and special offers from this restaurant."
              />

              <Form.Button content="SUBMIT" className="submitemailbutton" />
            </Form>
          </Segment>

          <Divider />

          <div className="poweredbydiv">
            <a //onClick={async () => await sendEvent(this.props.match.params.restaurant, 'basilhome', 'link')}
              id="basilhomelink"
              key="basilhomelink"
              href="https://instagram.com/basilmenu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="poweredbyspanpop">Powered by</span>
              <br />
              <Image
                id="poweredlogopop"
                size="mini"
                centered
                src={basillogosmall}
              />
            </a>
          </div>
          <br />
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

export default GuestRegistration;
