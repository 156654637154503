import React from 'react'
import { Switch, Route } from 'react-router-dom'
import GuestRegistration from './GuestRegistration.js'

const Switcher = props => {
    // console.log(props)
    return (
        < main >
            <Switch>
                <Route exact path='/:restaurant' component={GuestRegistration} />
            </Switch>
        </main >)
}

export { Switcher }